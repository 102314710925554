import { Grid, Radio } from '@mui/material';
import { Language } from '@nx-smartmonkey/shared/domain';
import { RoutalInputDatePicker, RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';
import moment from 'moment';

import { intl } from '../../../helpers/IntGlobalProvider';
import { Text } from '../../../react-components/Text';
import { SearchFilterComponent } from '../filter';
import { SearchFilterOperators } from '../filter/SearchFilter';
import { SearchFilterDateTime, SearchFilterDateTimeValue } from './SearchFilterDateTime';

interface GetSearchFilterDateTimeOptionsProps {
  operator: SearchFilterOperators;
  text: string;
  hasValue: boolean;
  isSelected: boolean;
}

const getSearchFilterDateTimeOptions = (filter: SearchFilterDateTime): GetSearchFilterDateTimeOptionsProps[] => [
  {
    operator: `gt`,
    text: intl.formatMessage({ id: `search_filters.gt` }),
    hasValue: true,
    isSelected: filter.isOperator(`gt`),
  },
  {
    operator: `lt`,
    text: intl.formatMessage({ id: `search_filters.lt` }),
    hasValue: true,
    isSelected: filter.isOperator(`lt`),
  },
  {
    operator: `eq`,
    text: intl.formatMessage({ id: `search_filters.eq` }),
    hasValue: true,
    isSelected: filter.isOperator(`eq`),
  },
  {
    operator: `unknown`,
    text: intl.formatMessage({ id: `search_filters.unknown` }),
    hasValue: false,
    isSelected: filter.isOperator(`unknown`),
  },
  {
    operator: `known`,
    text: intl.formatMessage({ id: `search_filters.known` }),
    hasValue: false,
    isSelected: filter.isOperator(`known`),
  },
];

interface SearchFilterDateTimeComponentProps {
  filter: SearchFilterDateTime;
  filterPosition: number;
  language?: Language;
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
  onChange: (filter: SearchFilterDateTime) => void;
  onRemoveFilter: () => void;
}

export const SearchFilterDateTimeComponent = ({
  filter,
  filterPosition,
  language,
  colors,
  onChange,
  onRemoveFilter,
}: SearchFilterDateTimeComponentProps) => {
  const getDatePickerFormat = (value: string) => {
    switch (language?.value) {
      case `en`:
        return moment(new Date(value)).format(`MM/DD/yyyy h:mm a`);
      default:
        return moment(new Date(value)).format(`DD/MM/yyyy h:mm a`);
    }
  };

  return (
    <SearchFilterComponent
      filter={filter}
      buttonId={`filter-date_time-${filterPosition}`}
      popoverContainerId={`filter-date_time-${filterPosition}-popover-container`}
      colors={colors}
      onRemoveFilter={onRemoveFilter}
      customResult={(filter) => {
        return (
          <Text
            style={{
              fontWeight: `normal`,
              cursor: `pointer`,
              color: filter.isValueValid() ? undefined : `${RoutalPalette.error.medium}`,
            }}
            intlId={`search_filters.${filter.getOperator()}.result`}
            intlValues={{
              field: intl.formatMessage({ id: `search_filters.filter.${filter.getField()}` }),
              value: filter.isValueValid()
                ? `"${getDatePickerFormat(filter.getValue())}"`
                : `"${intl.formatMessage({ id: `search_filters.missing_value` })}"`,
            }}
          />
        );
      }}
    >
      <Grid container direction="column" style={{ padding: `16px 0` }}>
        <VSpacer medium />

        {getSearchFilterDateTimeOptions(filter).map((option) => {
          return (
            <Grid container direction="column" wrap="nowrap" style={{ padding: `0 16px`, minWidth: `250px` }}>
              <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                alignItems="center"
                style={{
                  cursor: `pointer`,
                }}
                onClick={(event: any) => {
                  event.stopPropagation();
                  filter.setOperator(option.operator);
                  filter.setValue(new Date().toString() as SearchFilterDateTimeValue);
                  onChange(filter);
                }}
              >
                <Grid item>
                  <Radio
                    style={{
                      color: colors.primaryColor,
                    }}
                    checked={option.isSelected}
                    onChange={(event: any) => {
                      event.stopPropagation();
                      filter.setOperator(option.operator);
                      filter.setValue(new Date().toString() as SearchFilterDateTimeValue);
                      onChange(filter);
                    }}
                  />
                </Grid>

                <Grid item>
                  <Text style={{ cursor: `pointer` }}>{option.text}</Text>
                </Grid>
              </Grid>

              {option.isSelected && option.hasValue ? (
                <Grid item>
                  <RoutalInputDatePicker
                    portalId={`filter-date_time-${filterPosition}-popover-container`}
                    hasTime
                    dateValue={new Date(filter.getValue())}
                    onChange={(date: Date) => {
                      filter.setValue(date.toString() as SearchFilterDateTimeValue);
                      onChange(filter);
                    }}
                    locale={language?.value}
                  />
                </Grid>
              ) : null}
            </Grid>
          );
        })}
      </Grid>
    </SearchFilterComponent>
  );
};
