import {
  FilterBooleanIcon,
  FilterDateIcon,
  FilterEmailIcon,
  FilterExternalIdIcon,
  FilterLocationIcon,
  FilterNameIcon,
  FilterNumberIcon,
  FilterPhoneIcon,
  FilterStatusIcon,
  FilterSurveyIcon,
  FilterTextIcon,
  FilterTimeIcon,
  FilterUrlIcon,
  FilterVehicleIcon,
  FilterVolumeIcon,
  FilterWeightIcon,
} from '../../assets/img/filters';
import { Supervisor } from '../../domain/supervisor/Supervisor';
import { SearchFilterTypes } from './filter/SearchFilter';
import { SearchFilterListOptionProps } from './list/SearchFilterList';

interface SearchFilterOption {
  type: SearchFilterTypes;
  field: string;
  intlId: string;
  iconSrc: string;
  isCustomField: boolean;
  listOptions?: SearchFilterListOptionProps[];
  formatted?: (value: any) => any;
  computed?: (value: any) => any;
}

export const getAvailableOptions = (supervisor?: Supervisor): SearchFilterOption[] => {
  if (!supervisor) {
    return [];
  }

  const availableOption: SearchFilterOption[] = [
    ...(supervisor.isStopFieldEnabled(`label`)
      ? [
          {
            field: `label`,
            type: `string`,
            intlId: `search_filters.filter.label`,
            iconSrc: FilterNameIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    {
      field: `status`,
      type: `list`,
      intlId: `search_filters.filter.status`,
      iconSrc: FilterStatusIcon,
      isCustomField: false,
      listOptions: [
        {
          value: `pending`,
          intlId: `search_filters.filter.status.pending`,
        },
        {
          value: `completed`,
          intlId: `search_filters.filter.status.completed`,
        },
        {
          value: `incomplete`,
          intlId: `search_filters.filter.status.incomplete`,
        },
        {
          value: `canceled`,
          intlId: `search_filters.filter.status.canceled`,
        },
      ],
    } as SearchFilterOption,
    ...(supervisor.isStopFieldEnabled(`location_details`)
      ? [
          {
            field: `location_details`,
            type: `string`,
            intlId: `search_filters.filter.location_details`,
            iconSrc: FilterLocationIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`comments`)
      ? [
          {
            field: `comments`,
            type: `string`,
            intlId: `search_filters.filter.comments`,
            iconSrc: FilterTextIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    {
      field: `created_at`,
      type: `date_time`,
      intlId: `search_filters.filter.created_at`,

      iconSrc: FilterDateIcon,
      isCustomField: false,
    } as SearchFilterOption,
    ...(supervisor.isStopFieldEnabled(`client_external_id`)
      ? [
          {
            field: `client_external_id`,
            type: `string`,
            intlId: `search_filters.filter.client_external_id`,
            iconSrc: FilterTextIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`estimated_arrival_time`)
      ? [
          {
            field: `estimated_arrival_time`,
            type: `date_time`,
            intlId: `search_filters.filter.estimated_arrival_time`,
            iconSrc: FilterDateIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`estimated_departure_time`)
      ? [
          {
            field: `estimated_departure_time`,
            type: `date_time`,
            intlId: `search_filters.filter.estimated_departure_time`,
            iconSrc: FilterDateIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`planned_arrival_time`)
      ? [
          {
            field: `planned_arrival_time`,
            type: `time`,
            intlId: `search_filters.filter.planned_arrival_time`,
            iconSrc: FilterTimeIcon,
            isCustomField: false,
            computed: (value: number) => {
              if (value !== undefined && value !== null) {
                const newValue = value * 60;
                return newValue;
              }
              return null;
            },
            formatted: (value: number) => {
              if (value !== undefined && value !== null) {
                const newValue = value / 60;
                return newValue;
              }
              return null;
            },
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`planned_departure_time`)
      ? [
          {
            field: `planned_departure_time`,
            type: `time`,
            intlId: `search_filters.filter.planned_departure_time`,
            iconSrc: FilterTimeIcon,
            isCustomField: false,
            computed: (value: number) => {
              if (value !== undefined && value !== null) {
                const newValue = value * 60;
                return newValue;
              }
              return null;
            },
            formatted: (value: number) => {
              if (value !== undefined && value !== null) {
                const newValue = value / 60;
                return newValue;
              }
              return null;
            },
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`pin`)
      ? [
          {
            field: `pin`,
            type: `string`,
            intlId: `search_filters.filter.pin`,
            iconSrc: FilterTextIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`additional_info`)
      ? [
          {
            field: `additional_info`,
            type: `string`,
            intlId: `search_filters.filter.additional_info`,
            iconSrc: FilterTextIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`customer_text_field`)
      ? [
          {
            field: `customer_text_field`,
            type: `string`,
            intlId: `search_filters.filter.customer_text_field`,
            iconSrc: FilterTextIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`driver_info`)
      ? [
          {
            field: `vehicle_label`,
            type: `string`,
            intlId: `search_filters.filter.vehicle_label`,
            iconSrc: FilterVehicleIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`duration`)
      ? [
          {
            field: `duration`,
            type: `number`,
            intlId: `search_filters.filter.duration`,
            iconSrc: FilterTimeIcon,
            isCustomField: false,
            formatted: (value: number) => {
              if (value !== undefined && value !== null) {
                const newValue = value * 60;
                return newValue;
              }
              return null;
            },
            computed: (value: number) => {
              if (value !== undefined && value !== null) {
                const newValue = value / 60;
                return newValue;
              }
              return null;
            },
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`weight`)
      ? [
          {
            field: `weight`,
            type: `number`,
            intlId: `search_filters.filter.weight`,
            iconSrc: FilterWeightIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`volume`)
      ? [
          {
            field: `volume`,
            type: `number`,
            intlId: `search_filters.filter.volume`,
            iconSrc: FilterVolumeIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    // {
    //   field: `time_windows`,
    //   type: `Array`,
    //   displayable: true,
    // } as SearchFilterOption,
    // {
    //   field: `requires`,
    //   type: `Array`,
    //   displayable: true,
    //   filterable: true,
    //   sortable: false,
    // } as SearchFilterOption,
    ...(supervisor.isStopFieldEnabled(`cluster`)
      ? [
          {
            field: `cluster`,
            type: `string`,
            intlId: `search_filters.filter.cluster`,
            iconSrc: FilterTextIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`reward`)
      ? [
          {
            field: `reward`,
            type: `number`,
            intlId: `search_filters.filter.reward`,
            iconSrc: FilterNumberIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`max_delivery_time`)
      ? [
          {
            field: `max_delivery_time`,
            type: `number`,
            intlId: `search_filters.filter.max_delivery_time`,
            iconSrc: FilterTimeIcon,
            isCustomField: false,
            formatted: (value: number) => {
              if (value !== undefined && value !== null) {
                const newValue = value * 60;
                return newValue;
              }
              return null;
            },
            computed: (value: number) => {
              if (value !== undefined && value !== null) {
                const newValue = value / 60;
                return newValue;
              }
              return null;
            },
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`external_id`)
      ? [
          {
            field: `external_id`,
            type: `string`,
            intlId: `search_filters.filter.external_id`,
            iconSrc: FilterExternalIdIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`reference_person`)
      ? [
          {
            field: `reference_person`,
            type: `string`,
            intlId: `search_filters.filter.reference_person`,
            iconSrc: FilterTextIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`email`)
      ? [
          {
            field: `email`,
            type: `string`,
            intlId: `search_filters.filter.email`,
            iconSrc: FilterEmailIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`url`)
      ? [
          {
            field: `url`,
            type: `string`,
            intlId: `search_filters.filter.url`,
            iconSrc: FilterUrlIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`phone`)
      ? [
          {
            field: `phone`,
            type: `string`,
            intlId: `search_filters.filter.phone`,
            iconSrc: FilterPhoneIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopCompletedFieldEnabled(`created_at`) || supervisor.isStopCanceledFieldEnabled(`created_at`)
      ? [
          {
            field: `report_created_at`,
            type: `date`,
            intlId: `search_filters.filter.report_created_at`,
            iconSrc: FilterDateIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopCompletedFieldEnabled(`comments`) || supervisor.isStopCanceledFieldEnabled(`comments`)
      ? [
          {
            field: `report_comments`,
            type: `string`,
            intlId: `search_filters.filter.report_comments`,
            iconSrc: FilterTextIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopCompletedFieldEnabled(`signature`)
      ? [
          {
            field: `report_signature`,
            type: `boolean`,
            intlId: `search_filters.filter.report_signature`,
            iconSrc: FilterBooleanIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopCompletedFieldEnabled(`images`) || supervisor.isStopCanceledFieldEnabled(`images`)
      ? [
          {
            field: `report_images`,
            type: `boolean`,
            intlId: `search_filters.filter.report_images`,
            iconSrc: FilterBooleanIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopCanceledFieldEnabled(`cancel_reason`)
      ? [
          {
            field: `report_cancel_reason`,
            type: `list`,
            intlId: `search_filters.filter.report_cancel_reason`,
            iconSrc: FilterTextIcon,
            isCustomField: false,
            listOptions: [
              {
                value: `nobody`,
                intlId: `search_filters.filter.report_cancel_reason.nobody`,
              },
              {
                value: `wrong_address`,
                intlId: `search_filters.filter.report_cancel_reason.wrong_address`,
              },
              {
                value: `missing_information`,
                intlId: `search_filters.filter.report_cancel_reason.missing_information`,
              },
              {
                value: `other`,
                intlId: `search_filters.filter.report_cancel_reason.other`,
              },
            ],
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isSurveyFieldEnabled(`rating`)
      ? [
          {
            field: `survey`,
            type: `survey`,
            intlId: `search_filters.filter.survey`,
            iconSrc: FilterSurveyIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
    ...(supervisor.isStopFieldEnabled(`created_at`)
      ? [
          {
            field: `created_at`,
            type: `date`,
            intlId: `search_filters.filter.created_at`,
            iconSrc: FilterDateIcon,
            isCustomField: false,
          } as SearchFilterOption,
        ]
      : []),
  ];

  // TODO: CUSTOM FIELDS

  return availableOption;
};
