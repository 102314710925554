/* eslint-disable class-methods-use-this */
import { Language } from '@nx-smartmonkey/shared/domain';
import { Cookies, Requests } from '@nx-smartmonkey/shared/helpers';
import { CookiesConstants } from '../../constants/Cookies';
import { SessionRepository } from './SessionRepository';

export class SessionRepositoryApi implements SessionRepository {
  constructor(private requests: Requests, private cookies: Cookies) {}

  private codePending = false;

  private countryCode: string | undefined;

  private language: Language | undefined;

  isLogged(): boolean {
    return (
      this.cookies.hasCookie(CookiesConstants.SM_SUPERVISORS_COOKIE_NAME) ||
      this.cookies.hasCookie(CookiesConstants.SM_SUPERVISORS_ADMIN_COOKIE_NAME)
    );
  }

  getToken(): string | undefined {
    const adminToken = this.cookies.getCookie(CookiesConstants.SM_SUPERVISORS_ADMIN_COOKIE_NAME);
    const userToken = this.cookies.getCookie(CookiesConstants.SM_SUPERVISORS_COOKIE_NAME);
    return adminToken ?? userToken;
  }

  getAsSupervisor(): string | undefined {
    const asSupervisor = localStorage.getItem(`AS_SUPERVISOR`);
    return asSupervisor ?? undefined;
  }

  setLanguage({ language }: { language: Language }) {
    this.language = language;
  }

  getLanguage(): Language | undefined {
    return this.language;
  }

  isCodePending(): boolean {
    return this.codePending;
  }

  initializeAdmin({ token, asSupervisor }: { token: string; asSupervisor: string }) {
    this.cookies.setCookie(CookiesConstants.SM_SUPERVISORS_ADMIN_COOKIE_NAME, token, 360);
    localStorage.setItem(`AS_SUPERVISOR`, asSupervisor);
  }

  getAuthenticationHeader(): Record<string, any> {
    const token = this.getToken();
    return token ? { Authorization: `Bearer ${token}` } : {};
  }

  async createSessionToken({ projectId, phone }: { projectId: string; phone: string }): Promise<string> {
    const response = await this.requests.get({
      uri: `/supervisors/v1/login`,
      params: { phone, project_id: projectId },
    });

    const { token } = response.data;

    this.cookies.setCookie(CookiesConstants.PHONE_COOKIE_NAME, token, 360);
    this.cookies.deleteCookie(CookiesConstants.SM_SUPERVISORS_COOKIE_NAME);

    this.codePending = true;

    return token;
  }

  async verifySessionToken({ otpCode }: { token: string; otpCode: string }): Promise<string> {
    const token = this.cookies.getCookie(CookiesConstants.PHONE_COOKIE_NAME);

    if (!token) {
      throw new Error(`Could not verify session token, no phone token found`);
    }

    const response = await this.requests.get({ uri: `/supervisors/v1/validate`, params: { token, otp_code: otpCode } });

    this.codePending = false;

    const { token: verifiedToken } = response.data;

    this.cookies.setCookie(CookiesConstants.SM_SUPERVISORS_COOKIE_NAME, verifiedToken, 360);

    return verifiedToken;
  }

  cleanSessionParameters() {
    this.codePending = false;
    this.cookies.deleteCookie(CookiesConstants.PHONE_COOKIE_NAME);
    this.cookies.deleteCookie(CookiesConstants.SM_SUPERVISORS_COOKIE_NAME);
    this.cookies.deleteCookie(CookiesConstants.SM_SUPERVISORS_ADMIN_COOKIE_NAME);
    localStorage.removeItem(`AS_SUPERVISOR`);
  }

  async deleteSessionToken(): Promise<void> {
    try {
      const token = this.cookies.getCookie(CookiesConstants.SM_SUPERVISORS_COOKIE_NAME);

      if (!token) {
        this.cleanSessionParameters();
        throw new Error(`Could not logout: invalid session token`);
      }

      const response = await this.requests.get({
        uri: `/supervisors/v1/logout`,
        params: {},
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
        },
      });
      this.cleanSessionParameters();
      return response.data.token;
    } catch {
      this.cleanSessionParameters();
    }
  }

  async getCountry(): Promise<string> {
    if (this.countryCode) {
      return this.countryCode;
    }

    const requests = Requests.create(`https://get.geojs.io`);
    const response = await requests.get({ uri: `/v1/ip/geo.json` });

    if (response.status !== 200) {
      return Language.getDefaultLanguage().value.toUpperCase();
    }

    const { country_code } = response.data ?? {};
    return (country_code ?? Language.getDefaultLanguage().value).toUpperCase();
  }
}
