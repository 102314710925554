import { Language } from '@nx-smartmonkey/shared/domain';
import { useQuery } from '@tanstack/react-query';
import { sessionService } from '../../application/SessionService';

interface UseRetrieveSessionCountryResponseProps {
  countryCode: string;
}

// Hooks act as 'Adapter' layer.
export const useRetrieveSessionCountry = (): UseRetrieveSessionCountryResponseProps => {
  const { data: countryCode } = useQuery([`supervisors_session_country`], () => sessionService.getCountry(), {
    retry: 1,
    refetchOnWindowFocus: false,
  });

  return { countryCode: countryCode ?? Language.getDefaultLanguage().value.toUpperCase() };
};
