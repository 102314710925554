import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNotifications } from '../hooks/notifications/useNotifications';

import { RoutalPalette } from '@nx-smartmonkey/ui';
import darkIcon from '../../favicon_apple.png';
import lightIcon from '../../favicon_apple_white.png';
import { Project } from '../domain/project/Project';
import configuration from '../services/configuration';

interface FaviconManagerProps {
  project: Project;
}

export const FaviconManager = ({ project }: FaviconManagerProps) => {
  const { newNotifications } = useNotifications();
  const [faviconHref, setFaviconHref] = useState<string | undefined>(undefined);

  /**
   * Returns the path for a favicon based on the given color scheme.
   *
   * @param {boolean} isDarkMode If currently in dark mode
   */
  const changeFavicon = useCallback(
    (isDarkMode = false, notification = false) => {
      const favicon = project?.style?.favicon ?? (!isDarkMode ? darkIcon : lightIcon);

      if (!notification) {
        setFaviconHref(favicon);
        return;
      }

      // Create new favicon using the existing favicon but add red dot
      const img = document.createElement(`img`);
      img.src = favicon;
      img.onload = () => {
        const canvas = document.createElement(`canvas`);
        canvas.width = img.width;
        canvas.height = img.height;
        const context = canvas.getContext(`2d`);
        if (context) {
          context.drawImage(img, 0, 0, img.width, img.height);
          context.beginPath();
          context.arc(img.width - img.width / 5, img.height / 5, img.width / 5, 0, 2 * Math.PI);
          context.fillStyle = RoutalPalette.error.medium;
          context.fill();
        }
        setFaviconHref(canvas.toDataURL(`image/png`));
      };
    },
    [project?.style?.favicon]
  );

  useEffect(() => {
    // Get current color scheme.
    const matcher = window.matchMedia(`(prefers-color-scheme: dark)`);

    // Set favicon initially.
    changeFavicon(matcher.matches, newNotifications.length > 0);

    // Change favicon if the color scheme changes.
    matcher.onchange = () => changeFavicon(matcher.matches, newNotifications.length > 0);
  }, [changeFavicon, faviconHref, newNotifications]);

  let notificationNumber = undefined;
  if (newNotifications.length > 9) notificationNumber = ` +9 | `;
  else if (newNotifications.length > 0) notificationNumber = `${newNotifications.length} | `;
  else notificationNumber = ``;
  const pageTitle = notificationNumber + (project?.style?.page_title ?? `Supervisors`);

  const getEnvironmentChar = () => {
    switch (configuration.ENVIRONMENT) {
      case `DEVELOPMENT`:
        return `[D] `;
      case `STAGING`:
        return `[S] `;
      case `PRODUCTION`:
      default:
        return ``;
    }
  };

  return (
    <Helmet title={`${getEnvironmentChar()}${pageTitle}`} defer={false}>
      {faviconHref && <link rel="icon" type="image/png" href={faviconHref} />}
    </Helmet>
  );
};
