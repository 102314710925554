export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};
// Example:
// Input: 'Hello. How are you?'
// Output: 'Hello\. How are you\?'
// Input: 'This is a test string with special characters: *()^$'
// Output: 'This is a test string with special characters: \*\(\)\^\$'
// Input: 'Regex will be tricky with [brackets] and {curly braces}!'
// Output: 'Regex will be tricky with \[brackets\] and \{curly braces\}!'
// Input: 'Price is $10.99'
// Output: 'Price is \$10\.99'
export const escapeRegExpStrict = (string: string) => {
  // Escape special characters used in regular expressions to prevent issues in future regex processing
  // Those are the most common one, maybe there are more.
  return string.replace(/[[\]()*+?\\]/g, `\\$&`);
};
