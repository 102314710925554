import { SvgIcon } from '@mui/material';
import React from 'react';

interface LogoutProps {
  color?: string;
  style?: React.CSSProperties;
}

export const Logout = ({ color = `#2E3744`, style = {}, ...props }: LogoutProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 21" style={{ width: `20px`, height: `20px`, ...style }}>
      <g>
        <path
          d="M7.5 18H4.16667C3.72464 18 3.30072 17.8244 2.98816 17.5118C2.67559 17.1993 2.5 16.7754 2.5 16.3333V4.66667C2.5 4.22464 2.67559 3.80072 2.98816 3.48816C3.30072 3.17559 3.72464 3 4.16667 3H7.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 14.6666L17.5 10.5L13.3333 6.33331"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M17.5 10.5H7.5" stroke={color} fill="none" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </SvgIcon>
  );
};
