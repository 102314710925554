import { SearchPredicateDTO, SearchPredicateTypeDTO, ValueObject } from '@nx-smartmonkey/shared/domain';
import { escapeRegExpStrict } from '@nx-smartmonkey/shared/helpers';

export const searchOperators = [
  `lt`,
  `gt`,
  `eq`,
  `ne`,
  `unknown`,
  `known`,
  `true`,
  `false`,
  `starts_with`,
  `ends_with`,
  `contains`,
  `contains_exact_word`,
  `not_contains`,
] as const;
export type SearchOperator = (typeof searchOperators)[number];

export const searchTypes = [`string`, `date`, `boolean`, `number`, `list`, `survey`, `time`, `date_time`] as const;
export type SearchType = (typeof searchTypes)[number];

const allowedOperatorsByType: { [Key in SearchType]: SearchOperator[] } = {
  string: [
    `eq`,
    `ne`,
    `starts_with`,
    `ends_with`,
    `contains`,
    `contains_exact_word`,
    `not_contains`,
    `unknown`,
    `known`,
  ], // eslint-disable-line id-blacklist
  number: [`eq`, `ne`, `gt`, `lt`, `unknown`, `known`], // eslint-disable-line id-blacklist
  date: [`eq`, `ne`, `gt`, `lt`, `unknown`, `known`],
  boolean: [`eq`, `ne`, `unknown`, `known`], // eslint-disable-line id-blacklist
  list: [`eq`, `ne`, `unknown`, `known`], // eslint-disable-line id-blacklist
  survey: [`eq`, `gt`, `lt`], // eslint-disable-line id-blacklist
  time: [`eq`, `ne`, `gt`, `lt`, `unknown`, `known`],
  date_time: [`eq`, `gt`, `lt`, `unknown`, `known`],
};

export interface SearchPredicateProps {
  field: string;
  operator: SearchOperator;
  type: SearchType;
  value: any;
}

export class SearchPredicate extends ValueObject<SearchPredicateProps> {
  get field(): string {
    return this.props.field;
  }

  get operator(): SearchOperator {
    return this.props.operator;
  }

  get type(): SearchType {
    return this.props.type;
  }

  get value(): string | number | number[][] | string[] | boolean | null {
    return this.props.value;
  }

  private constructor(props: SearchPredicateProps) {
    super(props);
  }

  setValue(value: string | number | number[][] | string[] | null) {
    this.props.value = value;
  }

  toRepository(): SearchPredicateDTO {
    return {
      field: this.props.field,
      operator: this.props.operator,
      type: this.getTypeDTO(),
      value:
        this.getTypeDTO() === `string` && this.props.value
          ? escapeRegExpStrict(this.props.value as string)
          : this.props.value,
    };
  }

  private getTypeDTO(): SearchPredicateTypeDTO {
    let type = this.props.type;
    if (type === `list`) {
      type = `string`;
    }
    if (type === `survey`) {
      type = `number`;
    }
    if (type === `time`) {
      type = `number`;
    }
    if (type === `date_time`) {
      type = `date`;
    }
    return type;
  }

  isValid(): boolean {
    if (this.getTypeDTO() === `string` || this.getTypeDTO() === `number`) {
      return !!this.props.value || this.props.value === 0;
    }
    return true;
  }

  static create(props: SearchPredicateProps): SearchPredicate {
    if (!searchOperators.includes(props.operator)) {
      throw new Error(`Invalid search operator: ${props.operator}`);
    }
    if (!searchTypes.includes(props.type)) {
      throw new Error(`Invalid search type: ${props.type}`);
    }
    if (!allowedOperatorsByType[props.type].includes(props.operator)) {
      throw new Error(`Invalid search operator "${props.operator}" for type "${props.type}"`);
    }
    return new SearchPredicate(props);
  }

  static predicatesToBase64(predicates: SearchPredicate[]): string {
    if (predicates.length === 0) {
      return ``;
    }

    const mappedPredicates = predicates.map((p) => p.getValue());
    const stringified = JSON.stringify(mappedPredicates);
    const base64 = Buffer.from(stringified, `utf-8`).toString(`base64`);
    return base64;
  }
}
