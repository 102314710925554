import { CustomFieldDefinition } from './CustomFieldDefinition';
import { CustomFieldCategoricalMultiple, CustomFieldCategoricalSingle, CustomFields } from './CustomFields';
import { CustomFieldsDTO } from './CustomFieldsDTO';

export class CustomFieldsMapper {
  static toDomain(props: CustomFieldsDTO, definitions: CustomFieldDefinition[]): CustomFields {
    return CustomFields.create(props, definitions);
  }

  static toDTO(customFields: CustomFields): CustomFieldsDTO {
    return customFields.value as any;
  }

  static toRepository(customFields: CustomFields): CustomFieldsDTO {
    const parseCustomField = (
      customFieldDataValue:
        | string
        | number
        | boolean
        | string[]
        | CustomFieldCategoricalSingle
        | CustomFieldCategoricalMultiple,
      customFieldDefinition: CustomFieldDefinition
    ) => {
      if (customFieldDataValue === undefined) {
        return;
      }
      if (customFieldDataValue === null) {
        return customFieldDataValue;
      }
      if (customFieldDefinition.field_type === `categorical` && customFieldDefinition.multiple) {
        return (customFieldDataValue as CustomFieldCategoricalMultiple).map(({ id }) => id);
      }

      if (customFieldDefinition.field_type === `categorical` && !customFieldDefinition.multiple) {
        return [(customFieldDataValue as CustomFieldCategoricalSingle).id];
      }
      return customFieldDataValue;
    };

    if (Object.entries(customFields.getValue()).length > 0) {
      const customFieldsDTO = Object.entries(customFields.getValue()).reduce((prev, curr) => {
        const definition = customFields.getDefinition(curr[0]);
        // Check if the custom field is enabled
        if (definition?.enabled) {
          const value = parseCustomField(curr[1] as any, definition!);
          return { ...prev, [curr[0]]: value };
        }
        return prev;
      }, {});
      return customFieldsDTO;
    }
    return {};
  }
}
